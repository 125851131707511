// @ts-nocheck

import { PropsWithChildren, Ref, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import MapGL, { Source, Layer, NavigationControl, MapRef } from "react-map-gl";

import { BatchedPropertiesCollection } from "../../hooks/useBatchedProperties";

import Preloader from "../UI/Preloader/Preloader";

import { clusterLayerStyle, clusterCountLayerStyle, unclusteredPointLayerStyle, building3dLayerStyle } from "./layers";

import "./Map.scss";
import "./MapBox.scss";
import { Feature, Polygon } from "geojson";

interface MapProps extends PropsWithChildren {
	properties: BatchedPropertiesCollection & { propertiesInsidePolygons?: any };
	isLoading?: boolean;
	interactiveLayerIds: string[];
	onDrawingFeaturesChanged?: Function;
	onClick: Function;
	onMouseMove: Function;
	onReady: Function;
	drawingFeatures: Feature<Polygon>[];
}

const noop = (subject) => { };

export default function Map(props: MapProps) {
	const {
		properties,
		isLoading,
		children,
		interactiveLayerIds,
		onDrawingFeaturesChanged,
		onClick,
		onMouseMove,
		onReady,
		drawingFeatures,
	} = props;

	// NB: MCM - DO NOT REMOVE ME. Angular's lazy loader gets itself into a pickle when importing react-map-gl. We fixed this by explicitly importing mapbox-gl above. The following line ensures that there is a reference to mapboxgl so that webpack won't optimize it out.
	noop(mapboxgl.Map);

	// NB: We use a ref and bind these methods explicitly here because the only package I could find that hooks into this doesn't work correctly or support React 18 or MapBox 7 just yet (see https://github.com/amaurym/react-mapbox-gl-draw)
	const mapRef = useRef<Ref<MapRef>>();

	useEffect(() => {
		if (!mapRef.current) return;

		const map = mapRef.current;

		if (onReady) onReady(map);

		const onDrawCreate = (event) => {
			const state = [...drawingFeatures, event.features[0]];
			onDrawingFeaturesChanged(state);
		};

		const onDrawDelete = (event) => {
			// NB: Find the mirroring feature, Mapbox passes "by value" rather than passing the same instance we received above
			const mirror = drawingFeatures.find((feature) => feature.id === event.features[0].id);
			const index = drawingFeatures.indexOf(mirror);
			const state = [...drawingFeatures];

			state.splice(index, 1);

			onDrawingFeaturesChanged(state);
		};

		map.on("draw.create", onDrawCreate);
		map.on("draw.delete", onDrawDelete);

		return () => {
			map.off("draw.create", onDrawCreate);
			map.off("draw.delete", onDrawDelete);
		};
	}, [properties, onReady, onDrawingFeaturesChanged, drawingFeatures]);

	return (
		<div>
			<div className="map-container">
				<MapGL
					initialViewState={{
						latitude: 55.3781,
						longitude: -3.436,
						zoom: 5,
					}}
					mapboxAccessToken="pk.eyJ1IjoiYWxhbi1pcnRzdXJ2ZXlzIiwiYSI6ImNqemwxOGhwcTBvbHUzZnFubzZkMDJuNncifQ.ZUmGtGIoR-vBRy9SZsohFg"
					mapStyle="mapbox://styles/alan-irtsurveys/cm7kn4qoc009x01sb4e9le7kg"
					width="100%"
					height="80vh"
					ref={mapRef}
					interactiveLayerIds={[unclusteredPointLayerStyle.id!, ...(interactiveLayerIds ?? [])]}
					onClick={(event) => onClick(event)}
					onMouseMove={(event) => onMouseMove(event)}
				>
					<Layer {...building3dLayerStyle} />
					{/* TODO: Please sort this out */}
					{/* @ts-ignore */}
					<Source type="geojson" data={properties} cluster={true} clusterMaxZoom={14} clusterRadius={50}>
						<Layer {...clusterLayerStyle} />
						<Layer {...clusterCountLayerStyle} />
						<Layer {...unclusteredPointLayerStyle} />
					</Source>
					{children}
					<NavigationControl
						style={{
							left: 10,
							top: 10,
						}}
					/>
				</MapGL>
				{(properties.isLoading || isLoading) && (
					<Preloader status="Loading properties..." progress={properties.progress} />
				)}
			</div>
		</div>
	);
}
