import _ from "lodash";
import { FormEvent, Fragment, useEffect, useState } from "react";

import { ArrowRight, Plus } from "react-feather";
import { Divider, Form, FormField, FormGroup, Grid, GridColumn, GridRow, Header } from "semantic-ui-react";

import { useRestClientContext } from "../../../contexts/RestClientContext";
import {
	CustomImprovement,
	sapImprovementUnitsMap,
	SmartTargetFields,
	SmartTargetFilter,
	SmartTargetParams,
	SmartTargetWeight,
} from "../../../types/types";
import SmartTargetFilterModal from "../../Modal/SmartTargetFilterModal";
import Button from "../../UI/Button/Button";
import Checkbox from "../../UI/Checkbox/Checkbox";
import ChipLabel from "../../UI/ChipLabel/ChipLabel";

import "./GenerateSmartTarget.scss";
import Input from "../../UI/Input/Input";

interface GenerateSmartTargetProps {
	currentValues: Record<SmartTargetFields, { current?: number; validateAbove: boolean }>;
	isLoading: boolean;
	onSubmit: (params: SmartTargetParams) => void;
}

const ACTIVE_WEIGHTS = 2;

export default function GenerateSmartTarget({ currentValues, isLoading, onSubmit }: GenerateSmartTargetProps) {
	const [params, setParams] = useState<SmartTargetParams>({
		target: SmartTargetFields.SAP_VALUE,
		value: currentValues.sap_value.current ?? 0,
		weights: {
			cost: false,
			disruption: false,
		},
		filters: [],
	});
	const [filters, setFilters] = useState<SmartTargetFilter[]>([]);
	const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);

	const client = useRestClientContext();

	const goalUnit = sapImprovementUnitsMap[params.target].unit;
	const goalTimeFrame = sapImprovementUnitsMap[params.target].timeFrame;

	useEffect(() => {
		client
			.fetchCustomImprovements("alphabetically")
			.then((response: Response) => response.json())
			.then((json: CustomImprovement[]) => {
				setFilters(json.map((customImprovement) => ({ customImprovement, hidden: false, included: false })));
			});
		// just fetch on load - this is static data so doesn't need refreshing
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onTargetValueChange = (event: FormEvent<HTMLInputElement>) => {
		if (event.currentTarget.value === "") {
			setParams({ ...params, value: undefined });
		} else {
			const value = Number(event.currentTarget.value);
			setParams({ ...params, value });
		}
	};

	const onGoalChanged = (event: FormEvent<HTMLSelectElement>) => {
		setParams({
			...params,
			value: currentValues[event.currentTarget.value as SmartTargetFields].current ?? 0,
			[event.currentTarget.name]: event.currentTarget.value,
		});
	};

	const onWeightChange = (_: FormEvent, data: any) => {
		const toggleType = data.name as SmartTargetWeight;
		const newWeights = {
			...params.weights,
			[toggleType]: !!data.checked,
		};

		if (Object.values(newWeights).reduce((acc, curr) => acc + Number(curr), 0) === ACTIVE_WEIGHTS) {
			// can't have all checkboxes selected
			return;
		}

		setParams({
			...params,
			weights: newWeights,
		});
	};

	const isWeightDisabled = (weight: SmartTargetWeight): boolean => {
		const otherWeights = Object.entries(params.weights).filter((w) => w[0] !== weight);
		return otherWeights.reduce((acc, curr) => acc + Number(curr[1]), 0) === ACTIVE_WEIGHTS - 1;
	};

	const generateFilterText = (filter: SmartTargetFilter, filterName: string): string => {
		const splitCustom = filter.customImprovement.name.split("(");
		const name = splitCustom[0].trim() + (splitCustom[1] ? "*" : "");
		if (filterName === "hidden") {
			return `Hide: ${name}`;
		} else if (filterName === "included") {
			return `Forced: ${name}`;
		}
		return `${name} ${filter.operator} ${filter.value} ${filter.customImprovement.units}`;
	};

	const onGenerate = () => {
		// format for API validation and more efficient structure
		const formattedParams = { ...params } as any;
		// was using flatMap here but changed to filter -> map as MCM ES version is lower than DREam
		formattedParams.weights = Object.entries(params.weights)
			.filter(([weight, val]) => !!weight && val)
			.map(([weight, _val]) => weight);

		formattedParams.filters = filters.reduce(
			(acc, filter) =>
				filter.hidden || filter.included || (!!filter.operator && !!filter.value)
					? {
							...acc,
							[filter.customImprovement.name]: _.omit(filter, ["customImprovement"]),
					  }
					: acc,
			{}
		);
		onSubmit(formattedParams);
	};

	return (
		<>
			<Header size="small">Generate New Smart Target</Header>
			<Divider />
			<Form className="smart-target-form">
				<Grid>
					<GridRow>
						<GridColumn width={11}>
							<Grid>
								<GridRow>
									<GridColumn width={9}>
										<FormField>
											<label>Smart Target Goal</label>
											<select
												name="target"
												value={params.target}
												onInput={(event) => onGoalChanged(event)}
											>
												<option value="energy_saving">Energy saving</option>
												<option value="co2_saving">CO2 saving</option>
												<option value="cost_saving">Cost saving</option>
												<option value="sap_value">SAP value</option>
												<option value="co2_value">CO2 value</option>
												<option value="heat_demand">Heat demand</option>
												<option value="hlp">HLP</option>
											</select>
										</FormField>
									</GridColumn>
									<GridColumn width={7}>
										<FormField className="smart-target-input" style={{ whiteSpace: "nowrap" }}>
											<label>Target</label>
											{goalUnit === "£" && <span className="input-prefix">{goalUnit}</span>}
											<Input
												name="value"
												type="number"
												step="any"
												value={params.value}
												onChange={(event) => onTargetValueChange(event)}
											/>
											{!!goalUnit && goalUnit !== "£" && (
												<span className="input-suffix">
													{sapImprovementUnitsMap[params.target].unit}
												</span>
											)}
											<span className="input-suffix">
												{" "}
												{!!goalTimeFrame ? `per ${goalTimeFrame}` : ""}
											</span>
										</FormField>
									</GridColumn>
								</GridRow>
								<GridColumn width={16}>
									<FormField>
										<label>Filters</label>
										<div className="chip-filter-container">
											{filters.map((filter, index) =>
												Object.entries(filter).map(([filterName, filterVal]) =>
													(filterName === "hidden" && filterVal) ||
													(filterName === "included" && filterVal) ||
													(filterName === "value" && filterVal) ? (
														<ChipLabel
															key={filter.customImprovement.id + filterName}
															label={generateFilterText(filter, filterName)}
															closable
															rounded="rounded"
															onClose={() => {
																const newFilters = filters;
																newFilters[index] = {
																	...filter,
																	[filterName]: undefined,
																};
																setFilters(newFilters);
															}}
														/>
													) : (
														<Fragment
															key={filter.customImprovement.id + filterName}
														></Fragment>
													)
												)
											)}
										</div>
										<Button
											className="add-filter"
											variant="text"
											onClick={() => setFilterModalOpen(true)}
										>
											<Plus />
											Add Filter
										</Button>
									</FormField>
								</GridColumn>
								<GridRow>
									<GridColumn>
										<FormField>
											<label>Installation Weighting</label>
											<FormGroup className="smart-target-checkboxes">
												<Checkbox
													label="Cost"
													name="cost"
													checked={params.weights.cost}
													disabled={isWeightDisabled("cost")}
													onChange={onWeightChange}
												/>
												{/* Time currently disabled as we have no data currently */}
												{/*<Checkbox*/}
												{/*	label="Time"*/}
												{/*	name="time"*/}
												{/*	checked={params.weights.time}*/}
												{/*	disabled={isWeightDisabled("time")}*/}
												{/*	onChange={onWeightChange}*/}
												{/*/>*/}
												<Checkbox
													label="Disruption"
													name="disruption"
													checked={params.weights.disruption}
													disabled={isWeightDisabled("disruption")}
													onChange={onWeightChange}
												/>
											</FormGroup>
										</FormField>
									</GridColumn>
								</GridRow>
							</Grid>
						</GridColumn>
						<GridColumn width={5} verticalAlign="middle">
							<Button
								className="smart-target-submit"
								variant="contained"
								onClick={onGenerate}
								disabled={isLoading}
							>
								Generate Smart Target
								<ArrowRight />
							</Button>
						</GridColumn>
					</GridRow>
				</Grid>
			</Form>
			{isFilterModalOpen && (
				<SmartTargetFilterModal
					onClose={() => setFilterModalOpen(false)}
					improvementState={filters}
					onSaveFilters={(f) => {
						setFilters(f);
						setFilterModalOpen(false);
					}}
					nested
				/>
			)}
		</>
	);
}
