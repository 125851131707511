import { Header, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "semantic-ui-react";
import Case from "case";

import Property, { PropertyImage } from "../../rest/Property";
import Card from "../UI/Card/Card";
import EmblaCarousel from "../UI/Carousel/EmblaCarousel";

import "./PropertyImagesCard.scss";

interface PropertyImagesCardProps {
	property: Property;
}

export default function PropertyImagesCard({ property }: PropertyImagesCardProps) {
	/**
	 * format the performance score column, ensuring that both parts of the column are not null
	 * @param propertyImage
	 */
	const formatPerformanceScore = (propertyImage: PropertyImage) => {
		if (propertyImage.insulation_performance && propertyImage.analysis) {
			return `${propertyImage.insulation_performance}-${propertyImage.analysis}`;
		} else if (propertyImage.insulation_performance != null) {
			return String(propertyImage.insulation_performance);
		} else if (propertyImage.analysis) {
			return propertyImage.analysis;
		} else {
			return "N/A";
		}
	};

	const dataColumns: { label: string; column: keyof PropertyImage | ((propertyImage: PropertyImage) => string) }[] = [
		{ label: "Analysis", column: "result" },
		{ label: "Affected Areas", column: "affected_areas" },
		{ label: "Performance Score", column: formatPerformanceScore },
		{ label: "Additional Comments", column: "additional_comments" },
	];

	// this format will probably be changed so that properties will only have one property image data set
	const propertyImage = property.data.images && property.data.images[0];

	return (
		<Card title="Property Images" className="property-thermal-images">
			{property.data.images && propertyImage ? (
				<div className="property-thermal-images-container">
					<div>
						<EmblaCarousel slides={property.data.images} />
					</div>
					<Table celled>
						<TableHeader>
							<TableRow>
								<TableHeaderCell>Analysis Column</TableHeaderCell>
								<TableHeaderCell>Value</TableHeaderCell>
							</TableRow>
						</TableHeader>
						<TableBody>
							{dataColumns.map((columnFormat) => (
								<TableRow key={columnFormat.label}>
									<TableCell width={8}>
										<Header>{Case.title(columnFormat.label)}</Header>
									</TableCell>
									{typeof columnFormat.column === "function" ? (
										<TableCell width={8}>{columnFormat.column(propertyImage)}</TableCell>
									) : (
										<TableCell width={8}>{propertyImage[columnFormat.column] ?? "N/A"}</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			) : (
				<p>No images available</p>
			)}
		</Card>
	);
}
