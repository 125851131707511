/* mears-mcmview-override-contents
import { AngularWrapper } from "@irt-surveys/angular-react";
import { MatSpinnerOverlayComponent } from "../../../../shared/progress-spinner/mat-spinner-overlay.component";

export default function Preloader(props) {
	return <AngularWrapper
		component={MatSpinnerOverlayComponent}
		inputs={{
			mode: !!props.progress ? "determinate" : "indeterminate",
			value: props.progress,
			waitMessage: props.status,
		}} />;
};
 */

import React from "react";
import { Progress } from "semantic-ui-react";
import "./Preloader.scss";

interface PreloaderProps {
	fullscreen?: boolean;
	cover?: boolean;
	variant?: string;
	progress?: number;
	status?: string;
}

const Preloader = (props: PreloaderProps) => {
	const classNames = ["preloader-container"];

	if (props.fullscreen) classNames.push("fullscreen");
	if (props.cover) classNames.push("cover");

	if (props.variant) classNames.push(`variant-${props.variant}`);

	const classesAsString = classNames.join(" ");

	return (
		<div className={`whole-loader ${classesAsString}`}>
			<div className="loader-container">
				<div className="column">
					<div className="block"></div>
				</div>
				<div className="column">
					<div className="block"></div>
				</div>
				<div className="column">
					<div className="block"></div>
				</div>
				<div className="column">
					<div className="block"></div>
				</div>
			</div>
			{"progress" in props && (
				<div className="progress">
					<Progress percent={props.progress! * 100} indicating />
				</div>
			)}
			{props.status && <div className="status">{props.status}</div>}
		</div>
	);
};

export default Preloader;
