import { useState, SyntheticEvent, FormEvent } from "react";
import { Message, Form, Progress } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";

import Checkbox from "../UI/Checkbox/Checkbox";
import Select from "../UI/Select/Select";
import Modal from "../Modal/Modal";
import Button from "../UI/Button/Button";
import useBulkPropertyImageUpload from "../../hooks/useBulkPropertyImageUpload";
import useProcessImageUploadSpreadsheetFiles from "../../hooks/useProcessImageUploadSpreadsheetFiles";

import "./ImportPropertyImages.scss";

export default function ImportPropertyImages() {
	/////////////////////////////
	// CONSTANTS AND UTILITIES //
	/////////////////////////////
	const acceptedSpreadsheetTypes = {
		"text/csv": [".csv"],
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
	};

	const metaFields = ["analysis", "additional_comments", "result", "affected_areas", "insulation_performance"];

	const isFileSpreadsheet = (file: File) =>
		Object.keys(acceptedSpreadsheetTypes).find((mimeType) => file.type === mimeType);

	//////////////////////
	// STATE MANAGEMENT //
	//////////////////////
	const [spreadsheetFiles, setSpreadsheetFiles] = useState<File[]>([]);
	const [fieldMapping, setFieldMapping] = useState({});
	const [imageFields, setImageFields] = useState<string[]>([]);
	const [deleteExistingImages, setDeleteExistingImages] = useState(false);
	const [userInputError, setUserInputError] = useState<string | null>(null);

	///////////////
	// CALLBACKS //
	///////////////
	const onDrop = (acceptedFiles: File[]) => {
		const files = acceptedFiles.filter((file: File) => isFileSpreadsheet(file));

		if (files.length > 1) {
			setUserInputError("Too many spreadsheet files supplied. Only one spreadsheet is permitted in order to avoid field mapping ambiguity.");
			return;
		}

		setSpreadsheetFiles(files);
	};

	// TODO: Sort out any please
	const onFieldMappingChange = (event: SyntheticEvent<HTMLElement, Event>, data: any, destination: string) => {
		const { value } = data;

		setFieldMapping({
			...fieldMapping,
			[value]: destination,
		});
	};

	const onImageFieldToggle = (event: FormEvent<HTMLInputElement>, data: any, source: string) => {
		const fields: string[] = [...imageFields];
		const index = fields.indexOf(source);

		if (index > -1) fields.splice(index, 1);

		if (data.checked) fields.push(source);

		setImageFields(fields);
	};

	const onSubmit = () => {
		if (spreadsheetFiles.length === 0) {
			setUserInputError("No spreadsheet file selected. Please upload at least one spreadsheet in the supported formats (CSV / XLSX).");
			return;
		}

		if (!Object.values(fieldMapping).includes("uprn")) {
			setUserInputError("No UPRN field specified. Please select the column which holds UPRN's.");
			return;
		}

		if (imageFields.length === 0) {
			setUserInputError("No image fields selected. Please specify which column(s) hold image filenames.");
			return;
		}

		beginProcessing();
	};

	const onCancel = () => {
		cancelProcessing();
	};

	///////////
	// HOOKS //
	///////////
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		accept: {
			...acceptedSpreadsheetTypes,
			"image/jpeg": [".jpg", ".jpeg"],
		},
		onDrop,
	});

	const { header, keys, records } = useProcessImageUploadSpreadsheetFiles({ files: spreadsheetFiles });
	const duplicateHeaderFields = header.filter((e, i, a) => a.indexOf(e) !== i);
	const uniqueDuplicateFieldNames = duplicateHeaderFields.filter((e, i, a) => a.indexOf(e) === i);

	// TODO: Would be really really nice and clean to make a hook for handling extracting the records from the sheet as well...
	const { status, progress, errors, processing, beginProcessing, cancelProcessing } = useBulkPropertyImageUpload({
		acceptedFiles,
		records,
		fieldMapping,
		imageFields,
		deleteExistingImages,
	});

	/////////////
	// DISPLAY //
	/////////////
	const fieldSelectOptions = keys.map((key) => {
		return {
			key,
			text: key,
			value: key,
		};
	});

	return (
		<>
			<Form id="import" className="import-property-images">
				<Form.Field>
					<label>Files</label>
					<div {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						<p>Drag 'n' drop some files here, or click to select files</p>
					</div>
					<p>{records.length} records loaded</p>
					{uniqueDuplicateFieldNames.length > 0 && (
						<Message warning visible>
							<Message.Header>Non-unique field names</Message.Header>
							<p>The following field names are non-unique, this may lead to unexpected behaviour.</p>
							<ul>
								{uniqueDuplicateFieldNames.map((name) => (
									<li key={name}>{name}</li>
								))}
							</ul>
						</Message>
					)}
				</Form.Field>
				<Form.Field>
					<label>UPRN field</label>
					<Select
						options={fieldSelectOptions}
						placeholder="None"
						onChange={(event, data) => onFieldMappingChange(event, data, "uprn")}
					/>
				</Form.Field>
				<Form.Field>
					<label>Image fields</label>
					{keys.map((key) => {
						return (
							<div key={key}>
								<Checkbox
									label={key}
									onChange={(event, data) => onImageFieldToggle(event, data, key)}
								/>
							</div>
						);
					})}
				</Form.Field>
				<Form.Field>
					<label>Meta fields</label>
					<table>
						<tbody>
							{metaFields.map((name) => (
								<tr key={name}>
									<td>{name}</td>
									<td>
										<Select
											options={fieldSelectOptions}
											placeholder="None"
											clearable
											onChange={(event, data) => onFieldMappingChange(event, data, name)}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</Form.Field>
				<Form.Field>
					<label>Delete existing images</label>
					<Checkbox
						label="Delete existing property images before uploading new images"
						checked={deleteExistingImages}
						onClick={() => setDeleteExistingImages(!deleteExistingImages)}
					/>
				</Form.Field>
				{processing ? (
					<Form.Button onClick={onCancel}>Cancel</Form.Button>
				) : (
					<Form.Button onClick={onSubmit}>Upload</Form.Button>
				)}
			</Form>
			{processing && (
				<>
					<Progress percent={Math.round(progress * 100)} progress>
						{status}
					</Progress>
					{errors.map((message, index) => (
						<Message key={index} error>
							{message}
						</Message>
					))}
				</>
			)}
			{userInputError && (
				<Modal title="Invalid input" onClose={() => setUserInputError(null)}>
					<p>{userInputError}</p>
					<div className="button-container">
						<Button variant="contained" onClick={() => setUserInputError(null)}>
							Close
						</Button>
					</div>
				</Modal>
			)}
		</>
	);
}
