import { useState } from "react";

import Button from "../Button/Button";
import ExportVector from "../../../assets/ExportVector";


interface ExportButtonProps
{
	initiate(): Promise<void>;
	disabled?: boolean;
}

export default function ExportButton({ initiate, disabled }: ExportButtonProps) {
	const [loading, setLoading] = useState(false);

	const onExport = () => {
		setLoading(true);
		initiate()
			.then(() => setLoading(false))
			.catch(() => setLoading(false));
	};

	return (
		<Button variant="contained" disabled={loading || disabled} onClick={() => onExport()}>
			<div className="group-card-button">
				<ExportVector />
				Export
			</div>
		</Button>
	);
}
