/* mears-mcmview-override-contents
// not used in MCM
export default function MainNavigationMenu() {}
 */

import { useState } from "react";
import { Header, Icon } from "semantic-ui-react";
import { Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";

import { useUserContext } from "../contexts/UserContext";
import useScrollToTop from "../hooks/useScrollToTop";

import MainNavigationMenuItem from "./MainNavigationMenuItem";
import MainHeader from "../components/Layout/Header/Header";
import Home from "../pages/Dashboard";
import EpcData from "../pages/EpcData";
import DataIntegrity from "../pages/DataIntegrity";
import Mapping from "../pages/Mapping";
import Groups from "../pages/Groups";
import Teams from "../pages/Teams";
import Import from "../pages/Import";
import ImportMonitor from "../pages/ImportMonitor";
import Property from "../pages/Property";
import Team from "../pages/Team";
import ArrowVector from "../assets/ArrowVector";
import GroupVector from "../assets/GroupVector";
import DashboardVector from "../assets/DashboardVector";
import FileVector from "../assets/FileVector";
import MappingVector from "../assets/MappingVector";
import PaperClipVector from "../assets/PaperClipVector";
import HelpCircleVector from "../assets/HelpCircleVector";
import LoginVector from "../assets/LoginVector";
import { TeamVector } from "../assets/TeamVector";
import MainFooter from "./Layout/MainFooter/MainFooter";
import Button from "./UI/Button/Button";
import packageInfo from "../../package.json";

import "./MainNavigationMenu.scss";
import "./css-pro-layout.scss";

/**
 * Component as a routing intermediary to redirect traffic from the old group ID in path to the new query strings.
 */
const DataTableRedirect = () => {
	const params = useParams();
	let location = useLocation();
	const redirectTarget = location.pathname.split("/")[1];
	const queryParam = params.group_id ? `?group=${params.group_id}` : "";
	return <Navigate to={`/${redirectTarget}${queryParam}`} />;
};

export default function MainNavigationMenu() {
	const user = useUserContext();
	const [collapsed, setCollapsed] = useState(false);

	useScrollToTop();

	return (
		<>
			<div className={`main-layout has-sidebar`}>
				<aside id="sidebar" className={`sidebar break-point-md ${collapsed ? "collapsed" : ""}`}>
					<div>
						<img
							className="sidebar-logo-image"
							src={!collapsed ? "/assets/images/logo-with-text.png" : "/assets/images/logo-alone.png"}
							alt="DREam"
						/>
					</div>
					<nav id="main-nav" className={collapsed ? "collapsed" : "expanded"}>
						<MainNavigationMenuItem
							collapsed={collapsed}
							icon={<DashboardVector />}
							text="Dashboard"
							to="/"
						/>
						<MainNavigationMenuItem
							collapsed={collapsed}
							icon={<FileVector />}
							text="Data"
							to="/epc-data"
						/>
						<MainNavigationMenuItem
							collapsed={collapsed}
							icon={<LoginVector />}
							text="Data Quality"
							to="/data-integrity"
						/>
						<MainNavigationMenuItem
							collapsed={collapsed}
							icon={<MappingVector />}
							text="Mapping"
							to="/mapping"
						/>
						<MainNavigationMenuItem
							collapsed={collapsed}
							icon={<GroupVector />}
							text="Groups"
							to="/groups"
						/>
						<MainNavigationMenuItem collapsed={collapsed} icon={<TeamVector />} text="Teams" to="/teams" />
						{user.is_admin && (
							<MainNavigationMenuItem
								collapsed={collapsed}
								icon={<Icon name="upload" />}
								text="Import (Beta)"
								to="/import"
							/>
						)}
						<a
							href="https://app.powerbi.com/groups/47eab3b1-520c-4556-953d-11d5f9e37fee/reports/4482aff4-25db-4401-973b-b28ca73616f5?ctid=44bc7c72-1a46-4d32-aebb-041a8e58857c&amp;pbi_source=linkShare"
							target="_BLANK"
							rel="noopener noreferrer nofollow"
						>
							<Button variant="text">
								<PaperClipVector />
								{collapsed || "Report"}
							</Button>
						</a>
						<a
							href="https://irtsurveys.atlassian.net/servicedesk/customer/portals"
							target="_BLANK"
							rel="noopener noreferrer nofollow"
						>
							<Button variant="text">
								<HelpCircleVector />
								{collapsed || "Support"}
							</Button>
						</a>
						<div id="nav-col-3" className="nav-end-container">
							<div
								className={
									collapsed ? "arrow-vector-icon expand-navbar" : "arrow-vector-icon narrow-navbar"
								}
								onClick={() => setCollapsed(!collapsed)}
							>
								<ArrowVector />
							</div>
							<div style={{ display: !collapsed ? "block" : "none" }}>
								<Header as="h6" className="version-header">
									Version {packageInfo.version}
								</Header>
							</div>
						</div>
					</nav>
				</aside>
				<div className="main">
					<MainHeader />
					<main className="content">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/epc-data" element={<EpcData />} />
							<Route path="/mapping" element={<Mapping />} />
							<Route path="/groups" element={<Groups />} />

							{user.is_admin && (
								<>
									<Route path="/imports/:import_id" element={<ImportMonitor />} />
									<Route path="/import" element={<Import />} />
								</>
							)}

							{/* <Route path="/report" element={<Report />} /> */}
							{/* <Route path="/support" element={<Support />} /> */}

							<Route
								path="/data-integrity/groups/:group_id"
								Component={DataTableRedirect}
								element={<Navigate replace to={"/data-integrity/groups"} />}
							/>
							<Route path="/data-integrity" element={<DataIntegrity />} />

							<Route path="/epc-data/groups/:group_id" Component={DataTableRedirect} />

							<Route path="/epc-data/:id" element={<Property />} />

							<Route path="/teams/:team_id" element={<Team />} />
							<Route path="/teams" element={<Teams />} />
						</Routes>
					</main>
					<footer className="footer">
						<MainFooter />
					</footer>
				</div>
			</div>
		</>
	);
}
