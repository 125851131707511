import React, { useState, useEffect, useCallback } from "react";
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons";
import useEmblaCarousel from "embla-carousel-react";

import "./embla.scss";

interface Slide {
	url: string;
}

interface EmblaCarouselProps {
	slides: Slide[];
}

const EmblaCarousel = ({ slides }: EmblaCarouselProps) => {
	const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
	const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);
	const [reinitialise, setReinitialise] = useState<boolean>(true);

	const emblaAction = useCallback(
		(action?: ((param?: any) => void), index?: number) => {
			if (!embla || !action) {
				return;
			}
			// reinitialise the carousel on action as styling can affect the image breakpoints
			if (reinitialise) {
				embla.reInit();
				setReinitialise(false);
			}
			action(index);
		},
		[embla, reinitialise]
	);

	const scrollPrev = () => emblaAction(embla?.scrollPrev);
	const scrollNext = () => emblaAction(embla?.scrollNext);
	const scrollTo = (index: number) => emblaAction(embla?.scrollTo, index);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla, setSelectedIndex]);

	useEffect(() => {
		if (!embla) return;
		onSelect();
		setScrollSnaps(embla.scrollSnapList());
		embla.on("select", onSelect);
	}, [embla, setScrollSnaps, onSelect]);

	return (
		<>
			<div className="embla">
				<div className="embla__viewport" ref={viewportRef}>
					<div className="embla__container">
						{slides.map((obj: any, index: number) => (
							<div className="embla__slide" key={index}>
								<div className="embla__slide__inner">
									<div className="thermal-image-container">
										<img className="embla__slide__img" src={obj.url} alt="Property" />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
				<NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
			</div>
			<div className="embla__dots">
				{scrollSnaps.map((_, index) => (
					<DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
				))}
			</div>
		</>
	);
};

export default EmblaCarousel;
